export const environment = {
    production: false,
    hmr: false,
    frontendUrl: 'https://dev1.immosurance.net/yiiapp/frontend/web/',
    yiiUrl: 'https://dev1.immosurance.net/yiiapp/backend/web/',
    mailBoxUrl: 'https://mailboxdev.optima-crm.com/index.php?r=',
    apiUrl: 'https://dev1.immosurance.net/v3/',
    socketUrl: 'https://dev1.immosurance.net/v3/',
    remoteCdnUrl: 'https://images.optima-crm.com/',
    cdnUrl: 'https://images.optima-crm.com/',
    rootUrl: 'https://my.optima-crm.com/',
    appUrl: 'https://dev1.immosurance.net/',
    stripeKey: 'pk_test_vTUFxDzPRaSnwbg5R825nFpX00GUoujCFU',
    sentry: {
        dsn: 'https://7286a2a7b51b4979894ed60cd7242a40@o888757.ingest.sentry.io/5840780',
        origins: ["https://dev2.optima-crm.com/"]
    },
    s3Url: 'https://optimacrmbucket.s3.eu-west-3.amazonaws.com/',
    commonAgency: '56b06e4d2bc1d95f60cedef2',
    roAgency : '56af07692bc1d9325acedef2',
    roCostaBlancaAgency : '648809e1ecbc080c710a4aa8',
    roCostaDelSolAgency : '659fbe57a558621ef10034ea',
    fcsAgency: '596dbb3f7219354c738b4567',
    immoAgency: '6110fa9b8334050aac21e779',
    auctionOffice: '61cc1d01ce179b7079176464',
    casafariAgency: '64253e22c89dd014a300be6e',
    casafariPortugalAgency: '6583ff9f56bfbac46001684b',
    ibizaAgency: '5abe15f48280de6f6150c895',
    zpAgency: "5b3cdaee8280de32e53cc48d",
    jvAgency: "5d1dc667b15da7752b11664e",
};