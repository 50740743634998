import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from 'app/services';
import { CrmMenuService } from 'app/services/crm-menu.service';
import { CurrencyPipe } from '@angular/common';
import * as _ from 'lodash';

@Pipe({
  name: 'getCommercialPrice'
})
export class GetCommercialPricePipe implements PipeTransform {
  isCommHolidayRentalModule: any;
  isLongTermRentalModule: any;

  constructor(
    private _authenticationService: AuthenticationService,
    private _crmMenuService: CrmMenuService,
    private _currencyPipe: CurrencyPipe
  ) { 
    this.isCommHolidayRentalModule = this._crmMenuService.isCommHolidayRentalModule;
    this.isLongTermRentalModule = this._crmMenuService.isLongTermRentalModule;
  }

  transform(property: any, args?: any): any {
    let cPrice = '';
    let stPrice = '';
    let ltPrice = '';
    let commission = '';
    let dnp = 0;
    let auctionPrice = '';
    const user = this._authenticationService.currentUserValue;
    const currencyCode = property.currency ? property.currency : 'EUR';
    const symbol = currencyCode === 'AED' ? 'د.إ' : 'symbol-narrow';
    const defaultSeparators = user.default_separators ? user.default_separators : 'es_AR';
    if (property && property.price) {
      cPrice = this._currencyPipe.transform(property.price, currencyCode, symbol, '1.0-2', defaultSeparators);
    }
    if (property && !property.price) {
      cPrice = this._currencyPipe.transform(0, currencyCode, symbol, '1.0-2', defaultSeparators);
    }

    // if (property && (property.sale || property.transfer || property.nude_sale) && property.current_price) {
    //   cPrice = this._currencyPipe.transform(property.current_price, currencyCode, symbol, '1.0-2', defaultSeparators);
    // }
    // if (property && (property.sale || property.transfer) && !property.current_price) {
    //   cPrice = this._currencyPipe.transform(0, currencyCode, symbol, '1.0-2', defaultSeparators);
    // }
    // if (property && property.commission) {
    //   if (typeof property.commission === 'string') {
    //     let numericPart = property.commission.replace(/[^0-9.]/g, '');
    //     let commissionValue = parseFloat(numericPart);       
    //     if (!isNaN(commissionValue)) {
    //       commission = commissionValue.toString();
    //     }   
    //   } else {
    //     commission = property.commission.toString();
    //   }
    //   commission = this._currencyPipe.transform(commission, currencyCode, symbol, '1.0-2', defaultSeparators);
    // }
    // if (property && property.rent && property.st_rental && this.isCommHolidayRentalModule && property.rental_seasons) {
    //   let rentalSeasons = _.find(property.rental_seasons, (o) => this.todayExist(o.period_from, o.period_to) && o.new_price);
    //   if (!rentalSeasons) {
    //     dnp = 0;
    //     rentalSeasons = _.find(property.rental_seasons, (o) => {
    //       dnp = _.clone(o.new_price);
    //       return this.futureExist(o.period_from, o.period_to) && (o.new_price < dnp || dnp === 0);
    //     });
    //   }
    //   if (rentalSeasons) {
    //     stPrice = (cPrice ? ' / ' : '') + this._currencyPipe.transform(rentalSeasons.new_price, currencyCode, symbol, '1.0-2', defaultSeparators);
    //   }
    // }
    // if (property && property.rent && property.lt_rental && this.isLongTermRentalModule && property.period_seasons) {
    //   let periodSeasons = _.find(property.period_seasons, (o) => this.todayExist(o.period_from, o.period_to) && o.new_price);
    //   if (!periodSeasons) {
    //     dnp = 0;
    //     periodSeasons = _.find(property.period_seasons, (o) => {
    //       dnp = _.clone(o.new_price);
    //       return this.futureExist(o.period_from, o.period_to) && (o.new_price < dnp || dnp === 0);
    //     });
    //   }
    //   if (periodSeasons) {
    //     ltPrice = (cPrice ? ' / ' : '') + this._currencyPipe.transform(periodSeasons.new_price, currencyCode, symbol, '1.0-2', defaultSeparators);
    //   }
    // }
    // if((property?.sale == false) && (property?.rent == false)) {
    //   if(property && property.auction_tab && property?.starting_price) {
    //     ltPrice = '';
    //     stPrice = '';
    //     cPrice = '';
    //     auctionPrice = this._currencyPipe.transform(property.starting_price, currencyCode, symbol, '1.0-2', defaultSeparators)
    //   }
    // } else if(ltPrice == '' && property && property.rent && property.lt_rental && this.isLongTermRentalModule && property.period_seasons && property.period_seasons[0] && property.period_seasons[0]?.new_price) {
    //   return ltPrice = (cPrice ? cPrice + ' / ' : '') + this._currencyPipe.transform(property.period_seasons[0]?.new_price, currencyCode, symbol, '1.0-2', defaultSeparators);
    // } else if (!args) { // #2999 - Sales price repeated in Object expansion
    //   if (property.sale == true && property.rent == false) {
    //     cPrice = this._currencyPipe.transform(property?.current_price, currencyCode, symbol, '1.0-2', defaultSeparators)
    //     return cPrice
    //   } else if (property.sale == true && property.rent == true) {
    //     return cPrice + stPrice + ltPrice + auctionPrice;
    //   } else {
    //     return cPrice + stPrice + ltPrice + auctionPrice;
    //   }
    // }

    if (args === 'currency') {
      let currencySign = '';
      if (property.currency === 'GBP') {
        currencySign = ' £';
      } else if (property.currency === 'USD') {
        currencySign = ' $';
      } else {
        currencySign = ' €';
      }
      return currencySign;
    } else if (args === 'cPrice') {
      return cPrice;
    } else if (args === 'stPrice' && this.isCommHolidayRentalModule) {
      return stPrice.replace(/\//g,'');
    }else if (args === 'ltPrice' && this.isLongTermRentalModule) {
      return ltPrice.replace(/\//g,'');
    } else if (args === 'commission') {
      return commission;
    }
    return cPrice;
  }

  futureExist(dFrom: string | number | Date, dTo: string | number | Date): boolean {
    const currentDate = new Date();
    const minDate = new Date(dFrom);
    const maxDate = new Date(dTo);
    return minDate > currentDate || maxDate > currentDate;
  }

  todayExist(dFrom: string | number | Date, dTo: string | number | Date): boolean {
    if (typeof dFrom === 'number') {
      dFrom = _.size(_.toString(dFrom)) === 10 ? dFrom * 1000 : dFrom;
    }
    if (typeof dTo === 'number') {
      dTo = _.size(_.toString(dTo)) === 10 ? dTo * 1000 : dTo;
    }
    const currentDate = new Date();
    const minDate = new Date(dFrom);
    const maxDate = new Date(dTo);
    return currentDate > minDate && currentDate < maxDate;
  }

}
